import { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  RefreshControl,
  TouchableOpacity,
  FlatList,
} from "react-native";
import useFetch from "use-http";

import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../types";

export default function TabOneScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  // const [refreshing, setRefreshing] = useState(false);

  const {
    get,
    loading,
    error,
    response,
    data = [],
  } = useFetch("/v1/runs/", []);

  return (
    <View style={styles.container}>
      <FlatList
        data={!!data ? data.results : data}
        style={{ width: "100%" }}
        keyExtractor={(item) => item.id}
        refreshing={loading}
        renderItem={({ item }) => (
          <RunListItem
            run={item}
            onTap={() =>
              navigation.push("RunDetail", { runId: item.id, name: item.title })
            }
          />
        )}
      />
    </View>
  );
}

const RunListItem = ({ run, onTap }) => {
  const dateCreated = new Date(run.created);
  const dateModified = new Date(run.modified);
  console.log(`Run pk: ${run.id}`);
  return (
    <TouchableOpacity
      onPress={onTap}
      key={run.id}
      style={{ width: "100%", alignItems: "center" }}
    >
      <View
        style={{
          backgroundColor: "rgb(0, 174, 239)",
          padding: 32,
          margin: 8,
          width: "90%",
          borderRadius: 10,
        }}
      >
        <BlockItem value={run.title} label="Run title" />
        <BlockItem
          value={dateCreated.toLocaleString("en-US")}
          label="Creation date"
        />
        <BlockItem value={run.samples_count} label="Samples" />
        <BlockItem value={run.status} label="Status" />
        <BlockItem
          value={dateModified.toLocaleString("en-US")}
          label="Last updated"
        />
        <BlockItem value={run.type} label="Run type" />
        <BlockItem value={run.qs_machine} label="QS machine" />
      </View>
    </TouchableOpacity>
  );
};

const BlockItem = ({ value, label }) => {
  return (
    <View style={{ paddingBottom: 12, backgroundColor: "rgb(0, 174, 239)" }}>
      <Text style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
        {label}
      </Text>
      <Text style={{ color: "white", fontSize: 20 }}>{value}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
