import SegmentedControl from "@react-native-segmented-control/segmented-control";
import { useState } from "react";

import {
  SIM_FILE,
  SIM_GENERATE,
  SIM_MODES,
  SCN_POOL,
  SCN_RACK,
  SCN_TYPES,
} from "../constants/ScanImport";

export const ScanTypePick = ({ current_scan_type, set_scan_type }) => {
  const [currIdx, setCurrIdx] = useState(0);
  return (
    <SegmentedControl
      style={{ width: "90%" }}
      values={["Pool Scan", "Rack Scan"]}
      selectedIndex={currIdx}
      onChange={(event) => {
        setCurrIdx(event.nativeEvent.selectedSegmentIndex);
        set_scan_type(SCN_TYPES[event.nativeEvent.selectedSegmentIndex]);
      }}
    />
  );
};
