import { useState } from "react";
import { StyleSheet, TouchableOpacity, Button, Image } from "react-native";

import { Text, View, TextInput } from "../components/Themed";
import { RootStackScreenProps } from "../types";

import { APIEndpointBase } from "../constants/API";

export default function AuthScreen({ setAuthToken }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async () => {
    setIsError(false);
    console.log(email, password);
    const data = { username: email, password: password };
    const response = await fetch(`${APIEndpointBase}/v1/rest-auth/login/`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log("Return: ", json);
    console.log("Is ok: ", response.ok);
    if (response.ok) {
      setAuthToken(json["key"]);
    } else {
      setIsError(true);
    }
  };

  return (
    <View style={styles.container}>
      {!!isError && <Text>Email or password do not match.</Text>}
      <Image
        style={{ marginBottom: 48, height: 94, width: 138 }}
        source={require("../assets/images/miri-logo.png")}
      />
      <Text style={styles.title}>Sign In</Text>
      <View style={{ width: "90%" }}>
        <TextInput
          style={styles.input}
          onChangeText={(text) => setEmail(text)}
          value={email}
          keyboardType="email-address"
          placeholder="Email"
        />
        <TextInput
          style={styles.input}
          onChangeText={(text) => setPassword(text)}
          value={password}
          keyboardType="default"
          textContentType="password"
          placeholder="Password"
        />
        <Button
          title="Submit"
          onPress={handleSubmit}
          disabled={!email || !password}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
