import { useState, useEffect, useRef } from "react";
import { Button } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import useFetch from "use-http";

import { Text, View } from "../components/Themed";
import { APIEndpointBase } from "../constants/API";

export const ScanFileUpload = ({ scan_type, activeSessionId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { post, loading, error, response } = useFetch({
    cachePolicy: "no-cache",
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const pickFile = async () => {
    const file = await DocumentPicker.getDocumentAsync();
    console.log(file);
    setSelectedFile(file.file);
  };

  useEffect(() => {
    if (!!isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
        // ref.current.value = "";
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleUpload = async () => {
    console.log(
      "Will try to upload...",
      selectedFile,
      scan_type,
      activeSessionId
    );

    const formData = new FormData();
    formData.append("poolscan", selectedFile);
    formData.append("scan_type", scan_type);
    formData.append("activeSessionId", activeSessionId);

    await post("/admin/miri/poolscan/upload/", formData);
    console.log("Uploading finished!", error);
    if (!error) {
      setIsSuccess(true);
      setSelectedFile(null);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      {!!isSuccess && (
        <Text
          style={{
            fontSize: 28,
            backgroundColor: "green",
            padding: 16,
            marginBottom: 24,
          }}
        >
          Just created a new PoolScan
        </Text>
      )}
      {!!error && (
        <Text
          style={{
            fontSize: 28,
            backgroundColor: "red",
            padding: 16,
            marginBottom: 24,
          }}
        >
          {error}
        </Text>
      )}

      <Button
        title="Select file"
        onPress={pickFile}
        disabled={loading}
        style={{ marginBottom: 16 }}
      />
      {!!selectedFile && (
        <Text
          style={{
            fontSize: 16,
            backgroundColor: "rgb(0, 174, 239)",
            padding: 16,
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          {selectedFile.name}
        </Text>
      )}
      {!!selectedFile && (
        <Button title="Upload" onPress={handleUpload} disabled={loading} />
      )}
    </View>
  );
};
