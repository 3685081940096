import { useEffect, useState, useCallback } from "react";
import { StyleSheet, SectionList } from "react-native";

import useFetch from "use-http";
import { Text, View } from "../components/Themed";

import { APIEndpointBase } from "../constants/API";

var _ = require("lodash");

export default function RunDetailScreen({ route, navigation }) {
  const { runId } = route.params;

  const {
    get,
    loading,
    error,
    response,
    data = [],
  } = useFetch(`/v1/runs/results/${runId}/entries`, []);

  const [run, setRun] = useState([]);

  useEffect(() => {
    const entryItems = data.items;
    const entriesByResultDict = _.groupBy(entryItems, "analysis_result");

    const entriesList = [];
    for (const [key, value] of Object.entries(entriesByResultDict)) {
      console.log(`${key}: ${value}`);
      entriesList.push({ title: key, data: value });
    }
    setRun(entriesList);
  }, []);

  return (
    <View style={styles.container}>
      {!!run && (
        <SectionList
          style={{ width: "100%" }}
          sections={run}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item }) => (
            <Item
              sampleId={item.display_sample_id}
              companyShort={item.company_short}
              poolName={item.pool_name}
              poolSize={item.pool_size}
              wells={item.wells}
              mean={item.mean}
              std_dev=""
            />
          )}
          renderSectionHeader={({ section: { title } }) => (
            <View
              style={{
                marginTop: 16,
                marginRight: 16,
              }}
            >
              <Text style={styles.title}>{title}</Text>
            </View>
          )}
        />
      )}
    </View>
  );
}

const Item = ({
  sampleId,
  companyShort,
  poolName,
  poolSize,
  wells,
  mean,
  std_dev,
}) => {
  let readings = "";
  for (const [key, value] of Object.entries(mean)) {
    console.log(`${key}: ${value}`);
    if (!!value) console.log(Math.round(value * 100) / 100);
  }
  //   console.log("readings ===> ", console.log(JSON.stringify(readings, null, 4)));
  const readings_a = "";
  return (
    <View
      style={{
        backgroundColor: "rgb(0, 174, 239)",
        padding: 16,
        margin: 8,
        width: "90%",
        borderRadius: 10,
      }}
    >
      <BlockItem value={sampleId} label="Sample ID" />
      <BlockItem value={companyShort} label="Company" />
      <BlockItem value={poolName} label="Pool name" />
      <BlockItem value={poolSize} label="Pool size" />
      <BlockItem value={wells} label="Wells" />
      <BlockItem value={readings_a} label="Reading" />
    </View>
  );
};

const BlockItem = ({ value, label }) => {
  return (
    <View style={{ paddingBottom: 12, backgroundColor: "rgb(0, 174, 239)" }}>
      <Text style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
        {label}
      </Text>
      <Text style={{ color: "white", fontSize: 20 }}>{value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    paddingTop: 8,
    paddingLeft: 8,
    color: "rgb(0, 174, 239)",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
