import { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "use-http";

import { APIEndpointBase } from "./constants/API";
import { useUserAuthToken } from "./hooks/useUserAuthToken";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import AuthScreen from "./screens/AuthScreen";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const { storeData: setToken, getData: getToken, token } = useUserAuthToken();
  const colorScheme = useColorScheme();
  // const [authToken, setAuthToken] = useState("");

  const options = {
    interceptors: {
      request: async ({ options, url, path, route }) => {
        options.headers.Authorization = `Token ${token}`;
        return options;
      },
      response: async ({ response }) => {
        if (response.status == 401) {
          console.log("LOGOUT");
          setToken("");
        }
        return response;
      },
    },
  };

  useEffect(() => {
    getToken();
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else if (!token) {
    return <AuthScreen setAuthToken={(token) => setToken(token)} />;
  } else {
    return (
      <SafeAreaProvider>
        <Provider url={`${APIEndpointBase}`} options={options}>
          <Navigation colorScheme={colorScheme} />
          <StatusBar />
        </Provider>
      </SafeAreaProvider>
    );
  }
}
