import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";

export const useUserAuthToken = () => {
  const storageKey = "@authToken";
  const [token, setToken] = useState("");

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem(storageKey, value);
      setToken(value);
    } catch (e) {
      // saving error
    }
  };

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem(storageKey);
      console.log(`useUserAuthToken::getData: ${value}`);
      if (value !== null) {
        setToken(value);
      }
    } catch (e) {
      setToken("");
    }
  };

  return { storeData, getData, token };
};
