import { useEffect, useState } from "react";
import { Button, StyleSheet } from "react-native";
import useFetch from "use-http";

import { Text, View } from "../components/Themed";

import { APIEndpointBase } from "../constants/API";
import { ScanTypePick } from "../components/ScanTypePick";
import { ScanFileUpload } from "../components/ScanFileUpload";
import {
  SIM_FILE,
  SIM_GENERATE,
  SIM_MODES,
  SCN_POOL,
  SCN_RACK,
} from "../constants/ScanImport";

export default function TabTwoScreen() {
  const [scan_type, set_scan_type] = useState(SCN_POOL);

  const [activeSessionId, setActiveSessionId] = useState("");
  const [activeSessionCompanyName, setActiveSessionCompanyName] = useState("");

  const {
    get,
    loading,
    error,
    response,
    data = [],
  } = useFetch(`/v1/scans/sessions/active/`, {
    cachePolicy: "no-cache",
  });

  useEffect(() => {
    console.log("Fetch active session data");
    fetchData();
  }, []);

  const fetchData = async () => {
    await get();
  };

  useEffect(() => {
    if (!!data) {
      console.log("Response: ", data);
      setActiveSessionId(data.session_id);
      setActiveSessionCompanyName(data.company_name);
    } else {
      setActiveSessionId("");
      setActiveSessionCompanyName("");
    }
  }, [data]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Upload scan</Text>
      <ScanTypePick
        current_scan_type={scan_type}
        set_scan_type={set_scan_type}
      />
      {scan_type == SCN_POOL && !activeSessionId && (
        <>
          <Text
            style={{
              marginTop: 16,
              backgroundColor: "orange",
              opacity: 0.7,
              padding: 8,
              maxWidth: "90%",
            }}
          >
            You seem not to have a single active session. Open one and start
            importing!
          </Text>
          <Button title="Check again" onPress={fetchData} />
        </>
      )}
      {!!activeSessionId && (
        <Text
          style={{
            marginTop: 16,
            backgroundColor: "green",
            opacity: 0.7,
            padding: 8,
            maxWidth: "90%",
          }}
        >
          Active session for {activeSessionCompanyName}
        </Text>
      )}

      {(!!activeSessionId || scan_type === SCN_RACK) && (
        <View style={{ marginTop: 64 }}>
          <ScanFileUpload
            scan_type={scan_type}
            activeSessionId={activeSessionId}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 16,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
